import React from 'react';
import PropTypes from 'prop-types';

const TableOfContents = ({ headings }) => {
  return (
    <div>
      <div className="text-base font-semibold mb-2">Table of Contents</div>
      {/* <hr className="my-2 opacity-20 border-0 bg-gray-500 text-gray-500 h-px" /> */}
      <ul>
        {headings.map(heading => {
          if (heading.depth > 4) {
            return <div />;
          }

          return (
            <li className="list-disc list-inside">
              <a
                className=" hover:text-htgGreen font-light text-sm"
                href={`#${heading.id}`}
                key={heading.value}
              >
                {heading.value}
              </a>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

TableOfContents.propTypes = {
  headings: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      depth: PropTypes.number.isRequired
    })
  ).isRequired
};

export default TableOfContents;
