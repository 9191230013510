import React from "react";
import amazonLogo from "../../images/amazon-logo.png";
import primeLogo from "../../images/prime-logo.svg";
import tw from "twin.macro";

const Container = tw.div`relative overflow-hidden grid grid-cols-1 md:grid-cols-2 mt-12 mb-6 py-6 px-8 sm:flex-row bg-white border shadow-lg rounded-lg`;
const Col = tw.div`p-4 flex flex-col justify-center`;
const ImageContainer = tw.a`flex justify-center items-center`;
const Block = tw.a`relative bg-white border shadow-lg rounded-lg overflow-hidden`;

const HeaderText = tw.a`text-xl font-semibold text-htgGreen-dark hover:text-htgGreen-veryDark hover:underline`;
const SubHeaderText = tw.div`text-xl font-semibold`;
const Description = tw.div`text-gray-500 text-sm pt-6 whitespace-normal`;
const TextContainer = tw.div``;
const Button = tw.a`flex flex-row py-2 mt-6 mx-2 bg-yellow-400 hover:bg-yellow-600 cursor-pointer justify-center rounded-md`;
const Image = tw.img`h-auto w-auto`;
const Ribbon = tw.div`w-40 absolute right-0 top-0 bg-yellow-200 text-sm transform rotate-45 px-4 py-1 mt-6 -mr-16`;

const AmazonItemLg = (props) => {
  const { imageurl, producturl, prime, description, subheader, name } = props;
  return (
    <Container>
      <Col>
        <ImageContainer href={producturl} rel="noopener" target="_blank">
          <Image src={imageurl} alt={producturl} />
        </ImageContainer>
      </Col>
      <Col>
        <TextContainer>
          <HeaderText href={producturl} rel="noopener" target="_blank">{name}</HeaderText>
          <SubHeaderText>{subheader}</SubHeaderText> 
          <Description>{description}</Description>
        </TextContainer>
        <Button href={producturl} rel="noopener" target="_blank">
          Check price on
          <img className="w-16 h-5 ml-2 mt-2" src={amazonLogo} alt="Amazon" />
        </Button>
      </Col>
        {prime ? (
          <Ribbon>
            <img className="ml-7 w-10" src={primeLogo} alt="Amazon Prime" />
          </Ribbon>
        ) : null}
    </Container>
  );
};

export default AmazonItemLg;
