/* eslint-disable react/self-closing-comp */ // must have explicit closing tags for rehype-react
import React, { Component } from 'react';
import Tag from '../shared/tag/tag';

export default class IronheadVeg extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gallons: 0,
      micro: 0,
      bloom: 0,
      calmag: 0,
      dripClean: 0,
      sm90: 0,
      rootExcel: 0,
      photosynth: 0
    };
    this.onChangeGallons = this.onChangeGallons.bind(this);
  }

  onChangeGallons(event) {
    this.setState({
      gallons: event.target.value,
      micro: event.target.value * 6,
      bloom: event.target.value * 9,
      calmag: event.target.value * 1.5,
      dripClean: event.target.value * 1,
      sm90: event.target.value * 5,
      rootExcel: event.target.value * 1,
      photosynth: event.target.value * 7.5
    });
  }

  render() {
    const {
      gallons,
      micro,
      bloom,
      calmag,
      dripClean,
      sm90,
      rootExcel,
      photosynth
    } = this.state;

    return (
      <div className="my-4 pb-2 rounded-md bg-htgGreen-veryLight">
        <div className="px-4 py-3 font-bold bg-htgGreen rounded-t-md">
          <p>Quick Ironhead Veg calculator</p>
        </div>
        <div className="px-4 pt-4 flex flex-col space-y-6">
          <div className="flex flex-col">
            Gallons of nutrient solution
            <input
              className="input rounded-md border p-2"
              type="number"
              placeholder="Gallons"
              value={gallons}
              min="0"
              onChange={this.onChangeGallons}
            />
          </div>
          {/* TODO: Make these tags links? */}
          <div className="flex flex-col">
            <div className="flex flex-row space-x-2 space-y-2 flex-wrap">
              <div className="mt-2">
                {/* Space x/y doesnt put margin on first element so we need to do this */}
                <Tag text={`${micro} mL Micro`} />
              </div>
              <Tag text={`${bloom} mL Bloom`} />
              <Tag text={`${calmag} mL Cali-Magic`} />
              <Tag text={`${dripClean} mL Drip Clean`} />
            </div>
            <div className="flex flex-row items-center content-center justify-center my-4">
              <hr className="opacity-50 border-0 bg-gray-500 text-gray-500 h-px w-16" />
              <div className="mx-6 text-gray-500 opacity-90">OPTIONAL</div>
              <hr className="opacity-50 border-0 bg-gray-500 text-gray-500 h-px w-16" />
            </div>
            <div className="flex flex-row space-x-2">
              <Tag text={`${sm90} mL SM-90`} />
              <Tag text={`${rootExcel} mL Root Excelerator`} />
              <Tag text={`${photosynth} mL Photosynthesis Plus`} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
