/* eslint-disable react/self-closing-comp */ // must have explicit closing tags for rehype-react
import React from 'react';
import Tag from '../shared/tag/tag';

const IronheadVeg = () => {
  const [gallons, setGallons] = React.useState(0);
  const [micro, setMicro] = React.useState(0);
  const [bloom, setBloom] = React.useState(0);
  const [calmag, setCalman] = React.useState(0);
  const [dripClean, setDripClean] = React.useState(0);
  const [cannazym, setCannazym] = React.useState(0);
  const [floralicious, setFloralicious] = React.useState(0);
  const [nectar, setNectar] = React.useState(0);
  const [kool, setKool] = React.useState(0);
  const [kleen, setKleen] = React.useState(0);
  const [sm90, setSm90] = React.useState(0);
  const [photosynth, setPhotosynth] = React.useState(0);

  const onChangeGallons = event => {
    setGallons(event.target.value);
    setMicro(event.target.value * 4);
    setBloom(event.target.value * 10);
    setCalman(event.target.value * 1.5);
    setDripClean(event.target.value * 1);
    setCannazym(event.target.value * 5);
    setFloralicious(event.target.value * 1);
    setNectar(event.target.value * 2.5);
    setKool(event.target.value * 2);
    setKleen(event.target.value * 10);
    setSm90(event.target.value * 5);
    setPhotosynth(event.target.value * 7.5);
  };

  return (
    <div className="my-4 pb-2 rounded-md bg-htgGreen-veryLight">
      <div className="px-4 py-3 font-bold bg-htgGreen rounded-t-md">
        <p>Quick Ironhead Flower calculator</p>
      </div>
      <div className="px-4 pt-4 flex flex-col space-y-6">
        <div className="flex flex-col">
          Gallons of nutrient solution
          <input
            className="input rounded-md border p-2"
            type="number"
            placeholder="Gallons"
            value={gallons}
            min="0"
            onChange={onChangeGallons}
          />
        </div>
        {/* TODO: Make these tags links? */}
        <div className="flex flex-col">
          <div className="flex flex-row space-x-2 space-y-2 flex-wrap">
            <div className="mt-2">
              {/* Space x/y doesnt put margin on first element so we need to do this */}
              <Tag text={`${micro} mL Micro`} />
            </div>
            <Tag text={`${bloom} mL Bloom`} />
            <Tag text={`${calmag} mL Cali-Magic`} />
            <Tag text={`${dripClean} mL Drip Clean`} />
            <Tag text={`${cannazym} mL Cannazym`} />
            <Tag text={`${floralicious} mL Floralicious`} />
            <Tag text={`${nectar} mL FloraNectar`} />
            <Tag text={`${kool} mL FloraBloom`} />
          </div>
          <div className="flex flex-row items-center content-center justify-center my-4">
            <hr className="opacity-50 border-0 bg-gray-500 text-gray-500 h-px w-16" />
            <div className="mx-6 text-gray-500 opacity-90">OPTIONAL</div>
            <hr className="opacity-50 border-0 bg-gray-500 text-gray-500 h-px w-16" />
          </div>
          <div className="flex flex-row space-x-2">
            <Tag text={`${sm90} mL SM-90`} />
            <Tag text={`${photosynth} mL Photosynthesis Plus`} />
            <Tag text={`${kleen} mL FloraKleen`} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default IronheadVeg;
