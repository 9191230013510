/* eslint-disable react/self-closing-comp */ // must have explicit closing tags for rehype-react
import React, { Component } from 'react';
import Tag from '../shared/tag/tag';

export default class LucasCalculator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentppm: 0,
      targetppm: 0,
      gallons: 0,
      result: 0
    };
    this.onChangeCurrent = this.onChangeCurrent.bind(this);
    this.onChangeTarget = this.onChangeTarget.bind(this);
    this.onChangeGallons = this.onChangeGallons.bind(this);
    this.updateResult = this.updateResult.bind(this);
  }

  onChangeTarget(event) {
    this.setState({ targetppm: event.target.value });
    this.updateResult();
  }

  onChangeCurrent(event) {
    this.setState({ currentppm: event.target.value });
    this.updateResult();
  }

  onChangeGallons(event) {
    this.setState({ gallons: event.target.value });
    this.updateResult();
  }

  updateResult() {
    this.setState(prevState => ({
      result:
        ((prevState.targetppm - prevState.currentppm) / prevState.targetppm) *
        8 *
        prevState.gallons
    }));
  }

  render() {
    const { currentppm, targetppm, gallons, result } = this.state;
    const resultRounded = Math.round(result * 10) / 10;

    return (
      <article className="my-4 pb-2 rounded-md bg-htgGreen-veryLight">
        <div className="px-4 py-3 font-bold bg-htgGreen rounded-t-md">
          Lucas Formula add-back Calculator
        </div>
        <div className="px-4 pt-4">
          <div className="gap-4 inline-grid grid-cols-3">
            <div className="flex flex-col">
              <div>Current Concentration (PPM)</div>
              <input
                type="number"
                placeholder="Current PPM"
                value={currentppm}
                min="0"
                className="p-2 rounded-md border border-gray-400"
                onChange={this.onChangeCurrent}
              />
            </div>
            <div className="flex flex-col">
              <div>Target Concentration (PPM)</div>
              <input
                className="p-2 rounded-md border border-gray-400"
                type="number"
                placeholder="Target PPM"
                value={targetppm}
                min="0"
                onChange={this.onChangeTarget}
              />
            </div>
            <div className="flex flex-col">
              <div>Reservoir Capacity (Gallons)</div>
              <input
                type="number"
                className="p-2 rounded-md border border-gray-400"
                placeholder="Gallons"
                value={gallons}
                min="0"
                onChange={this.onChangeGallons}
              />
            </div>
          </div>
          <div className="mt-4 flex space-x-4">
            <Tag text={`${resultRounded} mL Micro`} />
            <Tag text={`${resultRounded * 2} mL Bloom`} />
          </div>
        </div>
      </article>
    );
  }
}
