import React from "react";
import primeLogo from "../../images/prime-logo.svg";
import tw from "twin.macro";

const Container = tw.div`relative overflow-hidden flex flex-col flex-grow  py-3 px-6 bg-white border shadow-lg rounded-lg h-full justify-center`;
const Col = tw.div`flex flex-col justify-center`;
const ImageContainer = tw.a`flex justify-center items-center`;

const HeaderText = tw.a`text-xl font-semibold text-htgGreen-dark hover:text-htgGreen-veryDark hover:underline`;
const SubHeaderText = tw.div` text-sm md:text-base mt-1 text-gray-600`;
const TextContainer = tw.div`text-center mt-6`;
const Image = tw.img`h-auto w-auto`;
const Ribbon = tw.div`w-40 absolute right-0 top-0 bg-yellow-200 text-sm transform rotate-45 px-4 py-1 mt-6 -mr-16`;

const AmazonItemSm = (props) => {
  const { imageurl, producturl, prime, subheader, name } = props;
  return (
    <Container>
      <Col>
        <ImageContainer href={producturl} target="_blank" rel="noopener">
          <Image src={imageurl} alt={producturl} />
        </ImageContainer>
      </Col>
      <Col>
        <TextContainer>
          <HeaderText href={producturl} target="_blank" rel="noopener">{name}</HeaderText>
          <SubHeaderText>{subheader}</SubHeaderText> 
        </TextContainer>
      </Col>
        {prime ? (
          <Ribbon>
            <img className="ml-7 w-10" src={primeLogo} alt="Amazon Prime" />
          </Ribbon>
        ) : null}
    </Container>
  );
};

export default AmazonItemSm;
