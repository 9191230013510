import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import RehypeReact from 'rehype-react';
import LucasCalculator from '../components/best-hydro-nutrients/LucasCalculator';
import IronheadVeg from '../components/best-coco-coir-nutrients/ironheadVeg';
import IronheadFlower from '../components/best-coco-coir-nutrients/ironheadFlower';
import AmazonItemLg from '../components/shared/amazon-item-lg';

import Layout from '../components/shared/layout';
import SEO from '../components/shared/seo';
import Tag from '../components/shared/tag/tag';
import TableOfContents from '../components/shared/table-of-contents/table-of-contents';
import AmazonItemSm from '../components/shared/amazon-item-sm';

const BlogPostTemplate = props => {
  const { data, location } = props;
  const post = data.markdownRemark;
  const renderAst = new RehypeReact({
    createElement: React.createElement,
    components: {
      'lucas-calculator': LucasCalculator,
      'ironhead-veg': IronheadVeg,
      'ironhead-flower': IronheadFlower,
      'amazon-item': AmazonItemLg,
      'amazon-item-sm': AmazonItemSm
    }
  }).Compiler;
  return (
    <Layout>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
        date={post.frontmatter.date}
        keywords={post.frontmatter.tags.join(', ')}
        imagePublicUrl={post.frontmatter.featuredImage.publicURL}
        type="article"
        path={location.pathname}
      />
      <div
        className="bg-htgGreen-veryLight absolute top-0 left-0 h-72 sm:h-80 w-full"
        style={{ zIndex: '-1' }}
      />
      <article className="mt-8">
        <top className="grid sm:grid-cols-3 gap-4">
          <div className="sm:col-span-2">
            <Img
              className="rounded-md max-h-96 max-w-lg"
              fluid={post.frontmatter.featuredImage.childImageSharp.fluid}
              objectFit="contain"
            />
            <div className="my-4 flex flex-row space-x-4">
              {post.frontmatter.tags.map(text => (
                <Tag text={text} />
              ))}
            </div>
            <h1 className="font-semibold text-2xl">
              {post.frontmatter.title}
            </h1>
            <div className="text-xl font-light">
              {post.frontmatter.description}
            </div>
            <div className="mt-6 flex flex-row justify-items-end">
              <div className="mr-auto font-extralight text-sm">{`Estimated ${post.timeToRead} min to read`}</div>
              <div className="font-extralight text-sm">
                {post.frontmatter.date}
              </div>
            </div>
          </div>
        </top>
        <articlebody className="mt-10 sm:grid sm:grid-cols-3 sm:gap-4">
          <div className="col-span-2">
            <hr className="mb-10 opacity-20 border-0 bg-gray-500 text-gray-500 h-px" />
            {renderAst(post.htmlAst)}
          </div>
          <div className="mt-10 hidden sm:block">
            <TableOfContents headings={post.headings} />
          </div>
        </articlebody>
      </article>
    </Layout>
  );
};

BlogPostTemplate.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object.isRequired,
  location: PropTypes.string.isRequired
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      tableOfContents
      headings {
        id
        depth
        value
      }
      timeToRead
      htmlAst
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        tags
        featuredImage {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
          publicURL
        }
      }
    }
  }
`;
